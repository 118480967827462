import * as React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

const ImageComponent = ({ photo, page }) => {
  return <>
    <div className={`main-image${page === 'index' ? ' main-image--large-on-mobile': ''}`}>
      <GatsbyImage
        image={photo}
        className={`main-image__photo main-image__photo--${page}`}
        alt="Bob Marsdon"
        loading="eager"
      />
      <div className="main-image__title">
        <h1><span className="main-image__firstname">Bob</span> <span className="main-image__lastname">Marsdon</span></h1>
      </div>
    </div>
  </>
}

export default ImageComponent