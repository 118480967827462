import * as React from 'react'
import { useState } from 'react'
import { Link } from 'gatsby'
import IconClose from '../icons/icon-close.svg'
import IconHamburger from '../icons/icon-hamburger.svg'

const pages = [
  { page: '', text: 'Biografie' },
  { page: 'media', text: 'Media' },
  /*{ page: 'concerts', text: 'Concerten' },
  { page: 'repertoire', text: 'Repertoire' },*/
  { page: 'contact', text: 'Contact' },
  { link: 'https://www.zetatrio.nl', text: 'Zèta Trio' },
]

const NavComponent = ({ activePage = '' }) => {
  const [navOpenOnMobile, setNavOpenOnMobile] = useState(false)

  return (
    <>
      <button className="hamburger" onClick={() => { setNavOpenOnMobile(true) }} aria-label="open-menu">
        <IconHamburger className="hamburger-icon" />
      </button>
      <nav className={`nav-bar${navOpenOnMobile ? ' nav-bar--open-on-mobile': ''}`}>
        <button className="nav-bar__close" onClick={() => setNavOpenOnMobile(false)}>
          <IconClose className="nav-bar__close-icon" />
        </button>
        {pages.map((item) => item.page !== undefined ? (
          <Link
            to={`/${item.page}`}
            className={`nav-bar__link${activePage === item.page ? ' nav-bar__link--active': ''}`}
            data-text={item.text}>{item.text}</Link>
        ) : (
          <a
            href={item.link}
            className={`nav-bar__link`}
            target="_blank"
            data-text={item.text}>{item.text}</a>
        ))}
      </nav>
    </>
  )
}

export default NavComponent